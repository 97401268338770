<div class="filter-menu">
  <app-filter-menu-saved-search-selector
    [fsi]="fsi"
    [searchContainer]="searchContainer"
    [trigger]="updateTrigger"
  />

  <div class="filter-menu__scrollable">
    <app-filter-menu-category-selector
      (triggerLoading)="triggerLoadingState()"
      [categories]="categories"
      [searchContainer]="searchContainer"
      [trigger]="updateTrigger"
    />

    @if (searchContainer.queryContainer?.selectedQueryMenu?.query_type !== 'advanced') {
      <app-filter-menu-filter-list
        [fsi]="fsi"
        [loading]="loadingState"
        [searchContainer]="searchContainer"
      />
    }
    @else {
      <app-filter-menu-search-suggestion-list
        [searchContainer]="searchContainer"
      />
    }
  </div>

  <app-floating-feature-toggle-switch
    feature="experimental.useNewFilterMenu"
    [featureLabel]="'TRANS__FEATURE__USE_NEW_FILTER_MENU' | translate"
    bottom="2rem"
    left="2rem"
    (afterToggle)="reloadPage()"
  >
    <span>
      {{ 'TRANS__OBJECT_PAGE__GO_BACK_TO_OLD_DESIGN' | translate }}
    </span>
  </app-floating-feature-toggle-switch>
</div>
