<app-widget-container>
  <div class="container">
    @if (articleLength()) {
      <span class="tips-heading">{{ 'TRANS__HIBERNATION__TIPS_HEADING_WITH_COUNT' | translate:{ index: currentIndex() + 1, totalCount: articleLength() } }}</span>
    } @else {
      <span class="tips-heading">{{ 'TRANS__HIBERNATION__TIPS_HEADING' | translate }}</span>
    }
    <div class="article-container" #articleContainer>

      @if (loading()) {
        <mat-spinner diameter="30"></mat-spinner>
      } @else if(currentTip()) {
        <div [@fadeInOut]="currentTip()">
          <h3>{{ currentTip().title }}</h3>
          <div [innerHTML]="currentTip().description"></div>
        </div>
      }
    </div>
    <div class="buttons">
      <button [disabled]="articleLength() < 2" (click)="onPreviousClick()" mat-button>
        {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__PREVIOUS_TIP_LABEL' | translate }}
      </button>
      <button [disabled]="articleLength() < 2" (click)="onNextClick()" mat-button>
        {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__NEXT_TIP_LABEL' | translate }}
      </button>
    </div>
  </div>
</app-widget-container>
