<ng-template #energySavingInfoContent>
  <div class="energy-saving">
    <span class="title">
      <mat-icon>energy_savings_leaf</mat-icon>
      {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__TITLE' | translate }}
    </span>
    <p>
      {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__DESCRIPTION' | translate }}
    </p>

    <ul class="description">
      @if (selectedInstance()) {
        <li>
          <span>
            {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__INSTANCE_LABEL' | translate }}
          </span>
          <span>{{ selectedInstance() }}</span>
        </li>
      }

      @if (userName()) {
        <li>
          <span>
            {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__USER_LABEL' | translate }}
          </span>
          <span class="user-name">
            {{ userName() }}
            <button mat-icon-button matTooltipClass="hibernate-wakeup-overlay-tooltip" [matTooltip]="'TRANS__HIBERNATE_WAKEUP_OVERLAY__LOGOUT_TOOLTIP' | translate" class="density--4" (click)="onLogout()">
              <mat-icon>logout</mat-icon>
            </button>
          </span>
        </li>
      }
    </ul>
  </div>
</ng-template>

@if (showOverlay()) {
  <div class="container" [@fadeInOut] cdkTrapFocus [cdkTrapFocusAutoCapture]="true">

    <div class="logo-wrapper">
      <div>
        <app-animated-primus-logo></app-animated-primus-logo>
      </div>
      <p>
        {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__WAKING_SYSTEM' | translate }}
      </p>
    </div>

    <div class="card-wrapper">
      <mat-card>
        <mat-card-content>

          <div class="card-content">

            <div class="article-wrapper">
              @if (loadingTipsArticles()) {
                <mat-spinner color="accent" diameter="20"></mat-spinner>
              } @else {
                @if (tipsArticles().length) {
                  <span class="tips-heading">{{ 'TRANS__HIBERNATION__TIPS_HEADING_WITH_COUNT' | translate:{ index: currentTipIndex() + 1, totalCount: tipsArticles().length } }}</span>
                } @else {
                  <span class="tips-heading">{{ 'TRANS__HIBERNATION__TIPS_HEADING' | translate }}</span>
                }
              }
              <article #articleRef>
                @if (currentTip()) {
                  <h2 [@fadeInOut]="currentTip()">{{ currentTip().title }}</h2>
                  <div [@fadeInOut]="currentTip()" [innerHTML]="currentTip().description"></div>
                }
              </article>
            </div>
            <aside>
              <ng-container *ngTemplateOutlet="energySavingInfoContent"></ng-container>
              <div class="buttons">
                <button mat-stroked-button (click)="onNext()">
                  {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__NEXT_TIP_LABEL' | translate }}
                </button>
                <button mat-stroked-button (click)="onPrevious()">
                  {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__PREVIOUS_TIP_LABEL' | translate }}
                </button>
              </div>

            </aside>

            <div class="mobile-footer">
              <button mat-stroked-button (click)="onPrevious()">
                {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__PREVIOUS_TIP_LABEL' | translate }}
              </button>
              <button mat-stroked-button (click)="onNext()">
                {{ 'TRANS__HIBERNATE_WAKEUP_OVERLAY__NEXT_TIP_LABEL' | translate }}
              </button>

              <button mat-icon-button [matMenuTriggerFor]="infoMenu">
                <mat-icon>information</mat-icon>
              </button>

              <mat-menu #infoMenu backdropClass="hibernate-wakeup-overlay-tooltip">
                <div class="footer-info-menu-content">
                  <ng-container *ngTemplateOutlet="energySavingInfoContent"></ng-container>
                </div>
              </mat-menu>
            </div>

          </div>

        </mat-card-content>

      </mat-card>
    </div>

  </div>
}
