import {logout, login} from '@ekultur/authentication';
import {Component, computed, effect, ElementRef, inject, signal, ViewChild} from '@angular/core';
import {MatCard, MatCardContent, MatCardFooter} from '@angular/material/card';
import {MatButton, MatIconButton} from '@angular/material/button';
import {animate, style, transition, trigger} from '@angular/animations';
import {HibernationService} from '../shared/hibernation.service';
import {AnimatedPrimusLogoComponent} from '../splash-screen/animated-primus-logo/animated-primus-logo.component';
import {MatIcon} from '@angular/material/icon';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {CdkTrapFocus} from '@angular/cdk/a11y';
import {MatTooltip} from '@angular/material/tooltip';
import {AsyncPipe, JsonPipe, NgTemplateOutlet} from '@angular/common';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {TranslateModule} from '@ngx-translate/core';
import {OperationService} from '../operations/operation.service';
import {LoginService} from '../core/login.service';
import {PrimusBackendInstanceService} from '../core/primus-backend-instance.service';
import {environment} from '../../environments/environment';

const animationTime = 200;
@Component({
  selector: 'app-hibernate-wakeup-overlay',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatButton,
    AnimatedPrimusLogoComponent,
    MatCardFooter,
    MatIconButton,
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    CdkTrapFocus,
    MatTooltip,
    NgTemplateOutlet,
    AsyncPipe,
    JsonPipe,
    MatProgressSpinner,
    TranslateModule
  ],
  templateUrl: './hibernate-wakeup-overlay.component.html',
  styleUrl: './hibernate-wakeup-overlay.component.scss',
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [style({ opacity: '0' }), animate(animationTime)]),
      transition('* => void', [animate(animationTime, style({ opacity: '0' }))]),
      transition('* => *', [
        style({ opacity: '0' }),
        animate(animationTime, style({ opacity: '1' })),
      ]),
    ])
  ],
})
export class HibernateWakeupOverlayComponent {

  private hibernationService = inject(HibernationService);
  private operationService = inject(OperationService);
  private loginService = inject(LoginService);

  showOverlay = this.hibernationService.isSleeping;

  userName = signal<string | null>(null);
  selectedInstance = signal<string | null>(null);

  @ViewChild('articleRef') articleRef: ElementRef;

  loadingTipsArticles = this.hibernationService.loadingUserTips;
  tipsArticles = this.hibernationService.userTips;

  currentTipIndex = signal(0);
  currentTip = computed(() => this.tipsArticles()?.[this.currentTipIndex()]);

  constructor() {
    effect(() => {
      try {
        if (this.showOverlay()) {

          this.selectedInstance.set(PrimusBackendInstanceService.getInstanceDetails()?.name ?? null);

          const idToken = localStorage.getItem('kit.id_token');
          if (idToken) {
            const userName = JSON.parse(b64DecodeUnicode(idToken.split('.')[1]))?.name;
            this.userName.set(userName ?? null);
          }
        }
      } catch (e) {
        console.error('failed to set username from id_token', e);
      }
    }, {
      allowSignalWrites: true
    });

  }

  onNext() {
    this.articleRef?.nativeElement?.scrollTo(0, 0);
    this.currentTipIndex.update(i => (i + 1) % this.tipsArticles().length);
  }

  onPrevious() {
    this.articleRef?.nativeElement?.scrollTo(0, 0);
    this.currentTipIndex.update(i => (i - 1 + this.tipsArticles().length) % this.tipsArticles().length);
  }

  onLogout() {
    const eCultureLogoutCallback = () => {
      PrimusBackendInstanceService.cleanInstanceDetails();

      setTimeout(() => {
        login({
          locationPostLogin: null,
          redirectUri: null,
          loginHost: environment.ekulturLoginGatewayURL
        })
      }, 500);
    }

    logout({
      signal: null,
      apiGateway: environment.ekulturAPIGatewayURL,
      onLogout: eCultureLogoutCallback
    });
  }

}

// utf-8 safe decoding of base64
// source: https://stackoverflow.com/a/30106551
function b64DecodeUnicode(str: string) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}
