import {Component, Input, OnChanges} from '@angular/core';
import {SearchContainer} from "../../../../core/definitions/search-container";
import {CheckFilter, CheckFilterGroup} from "../../../../core/definitions/search-objects";
import {AConst} from "../../../../core/a-const.enum";
import {TranslateModule} from "@ngx-translate/core";
import {SearchFilterService} from "../../../search-filter.service";
import {SearchResultViewType} from "../../../../core/definitions/search-result-view-type.enum";
import {SearchFacetService} from "../../../search-facet.service";
import {MatIcon} from "@angular/material/icon";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-filter-group',
  standalone: true,
  imports: [
    TranslateModule,
    MatIcon,
    MatCheckbox,
    FormsModule,
  ],
  templateUrl: './filter-group.component.html',
  styleUrl: './filter-group.component.scss'
})
export class FilterGroupComponent implements OnChanges {
  @Input() filterGroup: CheckFilterGroup;
  @Input() searchContainer: SearchContainer;

  constructor(
    private searchFacetService: SearchFacetService,
    private searchFilterService: SearchFilterService
  ) {}

  ngOnChanges() {
    if (this.searchContainer.searchResultViews.default === SearchResultViewType.SELECTOR ||
        this.searchContainer.searchResultViews.default === SearchResultViewType.CONTENT_LIST) {
      this.filterGroup.enabled = false;
    }
    else {
      if (!this.filterGroup.enabled) {
        this.filterGroup.enabled = !! this.filterGroup.filters.find((filter) => filter.checked_value !== undefined);

        if (this.filterGroup.enabled) {
          this.filterGroup.active = true;
        }
      }
    }
  }

  checkFilter(filter: CheckFilter) {
    this.searchFilterService.checkCheckFilter(filter, this.searchContainer, false).then(() => {
      this.searchFilterService.setVisibleFilters(this.filterGroup);
    })
  }

  get hasManyFilters(): boolean {
    let res = false;

    if (this.filterGroup?.checkFilters?.length > AConst.MAX_FILTER_LENGTH) {
      res = true;
    }

    return res;
  }
}
