import {Component, Input, OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import {SearchContainer} from "../../core/definitions/search-container";
import {
  FilterMenuCategorySelectorComponent,
  CategoryMenuItem
} from "./filter-menu-category-selector/filter-menu-category-selector.component";
import {
  FilterMenuSavedSearchSelectorComponent
} from "./filter-menu-saved-search-selector/filter-menu-saved-search-selector.component";
import {
  FilterMenuFilterListComponent
} from "./filter-menu-filter-list/filter-menu-filter-list.component";
import {
  FilterMenuSearchSuggestionListComponent
} from "./filter-menu-search-suggestion-list/filter-menu-search-suggestion-list.component";
import {SearchExecutorService} from "../search-executor.service";
import {SearchFilterService} from "../search-filter.service";
import {SearchViewCategoryMenuService} from "../search-view-category-menu.service";
import {SearchFacetService} from "../search-facet.service";
import {SearchViewMenu} from "../../core/definitions/search-objects";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {FocusServiceImplementation, SearchFocusService} from "../search-focus.service";
import {
  FloatingFeatureToggleSwitchComponent
} from "../../floating-feature-toggle-switch/floating-feature-toggle-switch.component";

@Component({
  selector: 'app-search-filter-menu-v2',
  standalone: true,
  imports: [
    FilterMenuCategorySelectorComponent,
    FilterMenuSavedSearchSelectorComponent,
    FilterMenuFilterListComponent,
    FilterMenuSearchSuggestionListComponent,
    FloatingFeatureToggleSwitchComponent,
    TranslateModule,
  ],
  templateUrl: './search-filter-menu-v2.component.html',
  styleUrl: './search-filter-menu-v2.component.scss'
})
export class SearchFilterMenuV2Component implements OnInit, OnDestroy {
  @Input() searchContainer: SearchContainer;

  categories: WritableSignal<CategoryMenuItem[]> = signal([]);
  fsi: FocusServiceImplementation;
  loadingState: boolean = false;
  updateTrigger: WritableSignal<boolean> = signal(false);

  constructor(
    private categoryMenuService: SearchViewCategoryMenuService,
    private searchExecutorService: SearchExecutorService,
    private searchFacetService: SearchFacetService,
    private searchFocusService: SearchFocusService,
    private searchFilterService: SearchFilterService,
    private translation: TranslateService
  ) {}

  ngOnInit() {
    this.fsi = this.searchFocusService.createFSI(this.searchContainer);
    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.initialize);
  }

  ngOnDestroy() {
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer, this.initialize);
  }

  reloadPage() {
    window.location.reload();
  }

  triggerLoadingState() {
    this.loadingState = true;
  }

  private initialize = () => {
    if (this.searchContainer) {
      this.setAvailableCategories();

      if (this.searchContainer.searchResult) {
        this.setCheckedFilters().then();
        this.setFilterCount();
      }
    }

    this.updateTrigger.update(current => !current);

    if (this.loadingState) {
      this.loadingState = false;
    }
  }

  private searchViewMenuToCategoryMenu(menuItem: SearchViewMenu): CategoryMenuItem {
    let item: CategoryMenuItem = {
      count: menuItem.count,
      disabled: false,
      label: signal<string>(this.translation.instant(menuItem.title)),
      path: menuItem.path,
      type: 'child'
    }

    if (menuItem.menus?.length > 0) {
      item.children = [];
      item.path = '';
      item.type = 'header';

      item.children.push({
        disabled: false,
        label: signal<string>(`${this.translation.instant('TRANS__SEARCH_MENU__CATEGORY_ALL')} ${this.translation.instant(menuItem.title).toLowerCase()}`),
        path: menuItem.path,
        type: 'child'
      });

      for (const subMenu of menuItem.menus) {
        item.children.push(this.searchViewMenuToCategoryMenu(subMenu));
      }
    }

    return item;
  }

  private async setCheckedFilters() {
    if (!this.searchContainer.filtersFacets.filterGroups) {
      await this.searchFilterService.setCheckFilterGroups(this.searchContainer);
    }
    this.searchContainer.filtersFacets.filterGroups = this.searchContainer.filtersFacets.filterGroups
      ? this.searchContainer.filtersFacets.filterGroups.sort((a, b) => this.translation.instant(a.title).localeCompare(this.translation.instant(b.title)))
      : undefined;
  }

  private setFilterCount() {
    for (const filterGroup of this.searchContainer.filtersFacets.filterGroups || []) {
      for (const filter of filterGroup.checkFilters || []) {
        filter.count = this.searchFilterService.getFilterCount(filter, this.searchContainer);
      }
    }
  }

  private setMenuCount(categories: SearchViewMenu[]) {
    if (this.searchContainer.currentPathView.path_name.indexOf(this.searchContainer.path) !== 0) {
      console.warn('Incompatible paths');
      return;
    }

    for (const mainMenu of categories) {
      if (this.searchContainer.path.indexOf(this.searchContainer.path) === 0) {
        mainMenu.count = this.searchFacetService.getMenuCount(mainMenu, this.searchContainer);

        if (mainMenu.menus) {
          for (const subMenu of mainMenu.menus) {
            subMenu.count = this.searchFacetService.getMenuCount(subMenu, this.searchContainer);
          }
        }
      }
    }
  }

  private setAvailableCategories() {
    let tempSearchViewMenus = this.categoryMenuService.getCategoryMenus(this.searchContainer.queryContainer.selectedQueryMenu.query_type === 'advanced' ? 'object_filter' : 'all_objects_home');

    this.setMenuCount(tempSearchViewMenus);

    let tempCategories = [];

    tempCategories.push({
      disabled: false,
      icon: 'blur_on',
      label: signal<string>(this.translation.instant('TRANS__SEARCH_MENU_ALL_CATEGORIES')),
      path: 'home',
      type: 'child'
    } as CategoryMenuItem);

    for (const menu of tempSearchViewMenus) {
      tempCategories.push(this.searchViewMenuToCategoryMenu(menu));
    }

    this.categories.set(tempCategories);
  }
}
