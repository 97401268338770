<div class="filter-list__container">
  <div class="filter-list__header filter-list__header--space-between">
    <span class="filter-list__label filter-list__label--large filter-list__label--padded-left">
      {{ 'TRANS__FILTER_MENU__FILTER_HEADER' | translate }}
    </span>

    @if(hasFiltersChecked()) {
      <button mat-button class="filter-list__header-button" (click)="clearSetFilters()">
        <span class="filter-list__label filter-list__label--small">
          {{ 'TRANS__FILTER_MENU__FILTER_CLEAR' | translate }}
        </span>
      </button>
    }
  </div>

  @if (loading) {
    <div class="skeleton-filters-container">
      <div class="skeleton-filter-row skeleton-filter-row--width-100 skeleton"></div>
      <div class="skeleton-filter-row skeleton-filter-row--width-100 skeleton"></div>
      <div class="skeleton-filter-row skeleton-filter-row--width-100 skeleton"></div>
      <div class="skeleton-filter-row skeleton-filter-row--width-100 skeleton"></div>
      <div class="skeleton-filter-row skeleton-filter-row--width-100 skeleton"></div>
      <div class="skeleton-filter-row skeleton-filter-row--width-100 skeleton"></div>
      <div class="skeleton-filter-row skeleton-filter-row--width-100 skeleton"></div>
      <div class="skeleton-filter-row skeleton-filter-row--width-100 skeleton"></div>
      <div class="skeleton-filter-row skeleton-filter-row--width-100 skeleton"></div>
      <div class="skeleton-filter-row skeleton-filter-row--width-100 skeleton"></div>
    </div>
  }
  @else {
    @if (searchContainer.filtersFacets?.filterGroups?.length < 1 && searchContainer.filtersFacets?.facetRangeGroups?.length < 1) {
      <div class="filter-list__surface filter-list__surface--div">
        <div class="filter-list__label filter-list__label--small filter-list__label--padded filter-list__label--subtle">
          {{ 'TRANS__FILTER_MENU__NO_FILTERS_AVAILABLE' | translate }}
        </div>
      </div>
    }

    <mat-accordion displayMode="flat" [multi]="true" class="density--2">
      <!--@if (searchContainer.filtersFacets.hierarchicFilterGroup) {
        <mat-expansion-panel [expanded]="searchContainer.filtersFacets.hierarchicFilterGroup.open" class="filter-list__surface" [class.mat-elevation-z]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="filter-list__header">
                <span class="filter-list__label">
                  {{ searchContainer.filtersFacets.hierarchicFilterGroup.title | translate }}
                </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-search-filter-hierarchic [sideMenu]="false" [searchContainer]="searchContainer" />
        </mat-expansion-panel>
      }-->

      @for(filterGroup of searchContainer.filtersFacets.filterGroups; track $index) {
        <mat-expansion-panel (expandedChange)="filterGroupPreExpanded($event, filterGroup)" (afterExpand)="filterGroupToggled(filterGroup)" [expanded]="filterGroup.enabled" class="filter-list__surface" [class.mat-elevation-z]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="filter-list__header">
                <span class="filter-list__label">
                  {{ filterGroup.title | translate }}
                </span>
                @if (getCount(filterGroup) > 0) {
                  <span class="filter-list__label filter-list__label--small filter-list__label--subtle">
                  &nbsp;{{ getCount(filterGroup) }}
                </span>
                }
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-filter-group
            [filterGroup]="filterGroup"
            [searchContainer]="searchContainer"
          />
        </mat-expansion-panel>
      }

      @for(filterGroup of searchContainer.filtersFacets.facetRangeGroups; track $index) {
        <mat-expansion-panel (afterExpand)="filterRangeGroupToggled(filterGroup)" [expanded]="filterGroup.enabled"  class="filter-list__surface" [class.mat-elevation-z]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="filter-list__header">
              <span class="filter-list__label">
                {{ filterGroup.title | translate }}
              </span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-filter-range
            [filterRange]="filterGroup"
            [searchContainer]="searchContainer"
          />
        </mat-expansion-panel>
      }
    </mat-accordion>
  }
</div>
