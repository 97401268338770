import { Component } from '@angular/core';
import {WidgetContainerComponent} from '../widget-container/widget-container.component';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FeatureFlagsService} from '../../core/feature-flags.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatButton} from '@angular/material/button';
import {MatCheckbox} from '@angular/material/checkbox';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-feature-flags-widget',
  standalone: true,
  imports: [
    WidgetContainerComponent,
    FormsModule,
    MatButton,
    MatCheckbox,
    ReactiveFormsModule,
    TranslateModule
  ],
  templateUrl: './feature-flags-widget.component.html',
  styleUrl: './feature-flags-widget.component.scss'
})
export class FeatureFlagsWidgetComponent {

  constructor(
    private fb: FormBuilder,
    private featureFlagService: FeatureFlagsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    this.featureFlagService.initFeatureFlags().then(() => {
      this.initFlags();
    })
  }

  form = this.fb.group({
    temporary: this.fb.group({}), experimental: this.fb.group({
      useNewObjectPage: this.featureFlagService.getFeatureFlags()?.experimental?.useNewObjectPage,
      useNewFilterMenu: this.featureFlagService.getFeatureFlags()?.experimental?.useNewFilterMenu
    }), permanent: this.fb.group({})
  })

  async initFlags() {
    const overrides = this.featureFlagService.getOverrides();
    this.form = this.fb.group({
      temporary: this.fb.group({}), experimental: this.fb.group({
        useNewObjectPage: this.featureFlagService.getFeatureFlags().experimental.useNewObjectPage, ...overrides?.experimental,
        useNewFilterMenu: this.featureFlagService.getFeatureFlags().experimental.useNewFilterMenu, ...overrides?.experimental
      }), permanent: this.fb.group({})
    });
  }

  saveFlags() {
    this.featureFlagService.setOverrides({
      ...this.form.getRawValue()
    })
    this.notify(this.translate.instant('TRANS__FEATURE_FLAGS_WIDGET__NOTIFY_SAVED'));
  }

  async resetFlags() {
    this.featureFlagService.clearOverrides();
    this.initFlags();
    this.notify(this.translate.instant('TRANS__FEATURE_FLAGS_WIDGET__NOTIFY_RESET'));
  }

  private notify(message: string) {
    this.snackBar.open(message, null, {
      duration: 3000,
    })
  }


}
