import {Injectable} from '@angular/core';
import {
  CheckFilter,
  CheckFilterGroup,
  Facet,
  FacetItem,
  FilterFilter,
  RangeGroup,
  SearchViewMenu,
  SearchViewPath
} from '../core/definitions/search-objects';
import {FieldValueService, FindMappedValueParams} from '../core/field-value.service';
import {SearchExecutorService} from './search-executor.service';
import {SearchFacetService} from './search-facet.service';
import {SearchContainer} from '../core/definitions/search-container';
import {AConst} from '../core/a-const.enum';
import {TranslateService} from '@ngx-translate/core';
import {SearchFilterGroupEnablerService} from "./search-filter-group-enabler.service";
import {Subject} from 'rxjs';
import {LoggerService} from "../core/logger.service";
import {SearchViewCategoryMenuService} from "./search-view-category-menu.service";

@Injectable({
  providedIn: 'root'
})
export class SearchFilterService {

  constructor(private logger: LoggerService,
              private translate: TranslateService,
              private fieldValueService: FieldValueService,
              private searchExecutorService: SearchExecutorService,
              private searchFacetService: SearchFacetService,
              private searchFilterGroupEnablerService: SearchFilterGroupEnablerService,
              private categoryMenuService: SearchViewCategoryMenuService) {
  }

  setTotalSelectedFilters(searchContainer: SearchContainer) {
    searchContainer.totalSelectedFilters = 0;
    const checkedFilters = this.getCheckedFilters(searchContainer);
    if (checkedFilters) {
      for (const filter of Object.values(checkedFilters)) {
        searchContainer.totalSelectedFilters += filter.length;
      }
    } else {
      console.warn('No checked filters found');
    }
  }

  getCheckedFilters(searchContainer: SearchContainer) {
    return searchContainer.filtersFacets.checkedFilters;
  }

  /**
   * @param searchContainer
   * The setFiltersChecked will set the searchContainer property
   * filtersChecked to "checked" if there are any checked filters.
   * It is used in searchFilterMenuSmall directive to flag whether
   * filters have been checked.
   */
  setFiltersChecked(searchContainer: SearchContainer) {
    let res = 'notChecked';
    const checkedFilters = this.getCheckedFilters(searchContainer);
    for (const filterName in checkedFilters) {
      if (checkedFilters.hasOwnProperty(filterName)) {
        const filters = checkedFilters[filterName];
        if (filters.length > 0) {
          res = 'checked';
          break;
        }
      }
    }
    searchContainer.filtersFacets.filtersChecked = res;
  }

  async setCheckFilterGroups(searchContainer: SearchContainer, skipPredefinedFilters?: boolean, keepExistingGroups?: boolean): Promise<void> {
    let checkFilterGroups: CheckFilterGroup[];
    const pathView = searchContainer.currentPathView;
    if (pathView) {
      if (pathView.search_view.check_filter_groups) {
        if (keepExistingGroups && searchContainer.filtersFacets.filterGroups) {
          checkFilterGroups = searchContainer.filtersFacets.filterGroups;
        } else {
          checkFilterGroups = this.getCheckFilterGroupsFromPathView(pathView)
        }
        this.searchFilterGroupEnablerService.enableCheckedFilterGroups(searchContainer, checkFilterGroups);
        for (const fGroup of checkFilterGroups) {
          await this.setCheckFilterGroupFilters(fGroup, searchContainer, skipPredefinedFilters);
        }
      }
    }
    searchContainer.filtersFacets.filterGroups = checkFilterGroups;
  }

  async checkMenuFilter(menu: SearchViewMenu, searchContainer: SearchContainer, noSearch?: boolean) {
    await this.checkFilter(new CheckFilter(menu.facet, menu.facet_values[0]), menu, searchContainer, noSearch);
  }

  async checkCheckFilter(checkFilter: CheckFilter, searchContainer: SearchContainer, getVisibleFilter: boolean, noSearch: boolean = false): Promise<void> {
    let visibleFilter = checkFilter;
    if (getVisibleFilter) {
      visibleFilter = this.getVisibleFilterFromCheckFilter(checkFilter, searchContainer);
    }
    await this.checkFilter(checkFilter, visibleFilter, searchContainer, noSearch);
  }


  // Creates a deep copy of checkFilterGroups
  getCheckFilterGroupsFromPathView(pathView: SearchViewPath): CheckFilterGroup[] {
    if (pathView.search_view.check_filter_groups) {
      return JSON.parse(JSON.stringify(pathView.search_view.check_filter_groups));
    } else {
      return null;
    }
  }

  private getVisibleFilterFromCheckFilter(checkFilter: CheckFilter, searchContainer: SearchContainer) {
    let res: CheckFilter;
    if (!searchContainer.filtersFacets.filterGroups) {
      searchContainer.filtersFacets.filterGroups = this.getCheckFilterGroupsFromPathView(searchContainer.currentPathView);
    }
    for (const filterGroup of searchContainer.filtersFacets.filterGroups) {
      if (!filterGroup.visibleFilters) {
        this.logger.warn('No visible filters found for filter group ' + filterGroup.title);
        continue;
      }
      for (const visibleFilter of filterGroup.visibleFilters) {
        if (visibleFilter.name === checkFilter.name && visibleFilter.value === checkFilter.checked_value) {
          res = visibleFilter;
          break;
        }
      }
      if (res) {
        break;
      }
    }
    return res;
  }

  setVisibleFilters(filterGroup: CheckFilterGroup) {
    filterGroup.visibleFilters = this.getMaxFilters(filterGroup, this.getQueriedFilters(filterGroup, filterGroup.checkFilters));
  }

  getFilterCount(filter: CheckFilter, searchContainer: SearchContainer): number {
    const facetCount = this.searchFacetService.getFacetCount(filter.name, searchContainer);
    let res = facetCount.itemCounts[filter.value] || facetCount.itemCounts[filter.name];
    if (res === undefined) {
      res = 0;
    }
    return res;
  }

  async setRangeFilter(rangeGroup: RangeGroup, range: Facet, searchContainer: SearchContainer) {
    const checkedFilters = this.getCheckedFilters(searchContainer);
    const rangeGroups = searchContainer.currentPathView.search_view.facet_range_groups;
    this.deleteCheckFilterValue(checkedFilters[this.getRangeFilterName(range)], range.oldRangeFilter);
    await this.checkFilter(new CheckFilter(this.getRangeFilterName(range), this.getRangeFilterValue(range)),
      range, searchContainer, false);
    for (const existingGroup of rangeGroups) {
      if (rangeGroup.title === existingGroup.title) {
        const facetRange = this.getFacetRangeFromTitle(existingGroup.facet_ranges, range.title);
        if (facetRange) {
          facetRange.start = range.start;
          facetRange.end = range.end;
        }
      }
    }
  }

  async checkRangeFilter(rangeGroup: RangeGroup, range: Facet, searchContainer: SearchContainer) {
    const rangeFilterName = this.getRangeFilterName(range);
    const unChecked = await this.unCheckOldRangeFilters(rangeGroup, rangeFilterName, searchContainer);
    if (!unChecked) {
      await this.checkFilter(new CheckFilter(rangeFilterName, this.getRangeFilterValue(range)),
        range, searchContainer, false);
    }
  }

  // LOOP-O-RAMA!!!
  setCheckedRangeFacets(searchContainer: SearchContainer) {
    const facetRangeGroups: Array<RangeGroup> = searchContainer.searchResult.facet_range_groups;
    for (const [filterName, filterValues] of Object.entries(this.getCheckedFilters(searchContainer))) {
      if (filterValues.length > 0) {
        for (const group of facetRangeGroups) {
          for (const range of group.facet_ranges) {
            if (this.getRangeFilterName(range) === filterName) {
              range.checked = true;
              this.setRangeFromFilter(range, filterValues);
            }
          }
        }
      }
    }
  }

  setOldRangeFilters(searchContainer: SearchContainer) {
    for (const group of searchContainer.searchResult.facet_range_groups) {
      for (const range of group.facet_ranges) {
        range.oldRangeFilter = this.getRangeFilterValue(range);
      }
    }
  }

  setCheckedFiltersFromSearchContainer(searchContainer: SearchContainer, hasPathView: boolean, defaultCheckedFilters: any) {

    const checkedFilters = this.getCheckedFilters(searchContainer);
    if (hasPathView) {
      this.checkMenuFiltersFromDefaultFilters(
        searchContainer,
        defaultCheckedFilters);
      this.checkMenusFromCheckedFilters(searchContainer);
    }

    if (!checkedFilters || Object.keys(checkedFilters).length === 0) {
      this.setCheckedFilters(searchContainer, {});
    } else if (searchContainer.focus.curFocusId) {
      this.setTotalSelectedFilters(searchContainer);
    }
  }

  // Setting default pre-checked filters must be run after initial search in case default filter causes no search results,
  // which will make it impossible to clear the default filter due to SOLR search not returning the filters.
  // When pre-checked filters are found, the filter will be set and a new search will be performed
  setDefaultCheckedFilters(searchContainer: SearchContainer) {
    // Must re-run search after setting pre-checked filters in order to set correct search result
    this.findPrecheckedFilters(searchContainer, (filter: CheckFilter) => {
      setTimeout(() => {
        // Need to wait a bit in order for GUI to update in time
        this.checkCheckFilter(filter, searchContainer, true).then();
      }, 500);
    });
  }

  private findPrecheckedFilters(searchContainer: SearchContainer, callback: any) {
    if (!searchContainer.currentPathView.search_view.check_filter_groups) {
      return false;
    }
    let filterGroups: CheckFilterGroup[];
    if (!searchContainer.filtersFacets.filterGroups) {
      filterGroups = this.getCheckFilterGroupsFromPathView(searchContainer.currentPathView);
    } else {
      filterGroups = searchContainer.filtersFacets.filterGroups;
    }
    if (!filterGroups) {
      console.log('filter groups not set yet')
      return false;
    }
    for (const checkFilterGroup of filterGroups) {
      for (const filter of Object.values(checkFilterGroup.filters)) {
        if (filter.checked_value !== null && filter.checked_value !== undefined) {
          callback(filter);
        }
      }
    }
  }

  private async getPreCheckedCheckFilter(filter: CheckFilter): Promise<CheckFilter> {
    const checkFilter = new CheckFilter(filter.name, filter.checked_value);
    checkFilter.preChecked = true;
    checkFilter.checked = true;
    checkFilter.noTransTitle = '';
    const params = {filters: {artifact_id: filter.checked_value}} as FindMappedValueParams;
    checkFilter.noTransTitle = await this.fieldValueService.findMappedValue(params);
    return checkFilter;
  }

  private getRangeFilterName(range: Facet) {
    return range.f_name + ':' + range.title;
  }

  private getRangeFilterValue(range: Facet): string {
    return '[' + range.start + ' TO ' + range.end + ']';
  }

  setCheckedFilters(searchContainer: SearchContainer, value: any) {
    searchContainer.filtersFacets.checkedFilters = value;
    this.setTotalSelectedFilters(searchContainer);
  }

  /*
  If a filter menu contains a pre-selected value, then only
  the single filter value will be displayed in the filter menu.
  If the user de-selects this value, then the filter menu needs to be
  rebuilt in order to include new filter values
   */
  async setCheckFilterMenusAfterSearch(searchContainer: SearchContainer) {
    if (!searchContainer.filtersFacets.filterGroups) {
      return;
    }
    for (const filterGroup of searchContainer.filtersFacets.filterGroups) {
      if (!filterGroup.enabled) {
        continue;
      }
      for (const filter of Object.values(filterGroup.filters)) {
        const facets = this.searchFacetService.getSearchFacet(filter, searchContainer);
        const namedFilterGroup = this.getNamedFilterGroup(searchContainer, filter.name);
        if (facets && namedFilterGroup) {
          if (facets.items.length > namedFilterGroup.checkFilters.length) {
            await this.setCheckFilterGroupFilters(namedFilterGroup, searchContainer);
          }
        }
      }
    }
  }

  private async setCheckFilterGroupFilters(filterGroup: CheckFilterGroup,
                                           searchContainer: SearchContainer,
                                           skipPredefinedFilters?: boolean) {
    let checkFilters = [];
    let filterNames: string[] = [];
    let isPredefinedFilters = false;
    filterGroup.totalCount = 0;
    if (skipPredefinedFilters && filterGroup.enabled) {
      filterGroup.enabled = false;
      filterGroup.active = false;
      filterGroup.filterNames = [];
    }
    for (const filter of filterGroup.filters) {
      if (!filterNames.includes(filter.name)) {
        filterNames.push(filter.name);
      }
      if (filterGroup.enabled) {
        const facet = this.searchFacetService.getSearchFacet(filter, searchContainer);
        this.checkSetFilterGroupCountFromFacet(facet, filterGroup, filter);
        // isPredefinedFilters will always be false is skipPredefinedFilters is true
        isPredefinedFilters = await this.setCheckFiltersForFilter(
          filter, checkFilters, facet, isPredefinedFilters, skipPredefinedFilters);
      }
    }
    filterGroup.filterNames = filterNames;
    this.checkSetFilterChecked(searchContainer, checkFilters);
    if (isPredefinedFilters) {
      checkFilters = this.checkIfThereArePredefinedFilterValues(checkFilters, searchContainer);
    }
    filterGroup.checkFilters = checkFilters;
    filterGroup.filterFilter = new FilterFilter();
    this.setVisibleFilters(filterGroup);
  }

  private async setCheckFiltersForFilter(
    filter: CheckFilter,
    checkFilters: CheckFilter[],
    facet: Facet,
    isPredefinedFilters: boolean,
    skipPredefinedFilters?: boolean) {
    if (filter.value !== null && filter.value !== undefined) {
      if (!skipPredefinedFilters) {
        checkFilters.push(filter);
        isPredefinedFilters = true;
      }
    } else if (facet) {
      if (facet.items.length) {
        const checkFilter = this.getCheckFiltersFromFacet(facet, filter);
        checkFilters.push(...checkFilter);
      } else if (filter.checked_value !== null && filter.checked_value !== undefined) {
        const preCheckedCheckFilter = await this.getPreCheckedCheckFilter(filter);
        checkFilters.push(preCheckedCheckFilter);
      }
    }
    return isPredefinedFilters;
  }

  private checkSetFilterGroupCountFromFacet(facet: Facet, filterGroup: CheckFilterGroup, filter: CheckFilter) {
    if (facet) {
      if (filter.value !== null && filter.value !== undefined && filter.value !== true) {
        const facetItem = this.getFacetItemFromFilter(facet, filter);
        filterGroup.totalCount += facetItem.count;
      } else {
        filterGroup.totalCount += facet.total_count;
      }
    }
  }

  // This prevents "predefined filters", e.g. "has text blocks", from being displayed if there are no facet
  // values
  private checkIfThereArePredefinedFilterValues(checkFilters: CheckFilter[], searchContainer: SearchContainer) {
    return checkFilters.map(checkFilter => this.searchFacetService.getSearchFacet(
      checkFilter, searchContainer)).some(facet => facet?.items.length) ? checkFilters : [];
  }

  private getCheckFiltersFromFacet(facet: Facet, filter: CheckFilter): Array<CheckFilter> {
    const res: Array<CheckFilter> = [];
    for (const facetItem of facet.items) {
      const checkFilter = new CheckFilter(filter.name, facetItem.id ? facetItem.id : facetItem.name);
      if (filter.translate) {
        checkFilter.title = facetItem.name;
      } else {
        checkFilter.noTransTitle = facetItem.name;
      }
      res.push(checkFilter);
    }
    return res;
  }

  // This function is necessary in order to show that filters set from focuses are checked
  private checkSetFilterChecked(searchContainer: SearchContainer, checkFilters: Array<CheckFilter>) {
    const checkedFilters = this.getCheckedFilters(searchContainer);
    for (const checkFilter of checkFilters) {
      if (checkedFilters[checkFilter.name]) {
        for (const checkedValue of checkedFilters[checkFilter.name]) {
          if (checkFilter.value === checkedValue) {
            checkFilter.checked = true;
          }
        }
      }
    }
  }

  private checkDeletePreCheckFilter(searchContainer: SearchContainer, filter: CheckFilter) {
    let filterGroup: CheckFilterGroup;
    if (filter.preChecked) {
      filterGroup = this.getNamedFilterGroup(searchContainer, filter.name);
      if (filterGroup) {
        this.findDeleteFilterGroupFilter(filterGroup.checkFilters, 'value', filter);
      }
    }
  }

  private async checkFilter(filter: CheckFilter, checkObject: any, searchContainer: SearchContainer, noSearch?: boolean): Promise<void> {
    const checkedFilters = this.getCheckedFilters(searchContainer);
    if (!checkedFilters[filter.name]) {
      checkedFilters[filter.name] = [];
    }
    const filterValue = filter.value !== undefined ? filter.value : filter.checked_value;
    const existIndex = this.checkFilterExists(checkedFilters[filter.name],
      filterValue);
    if (existIndex !== -1) {
      checkedFilters[filter.name].splice(existIndex, 1);
      this.checkDeletePreCheckFilter(searchContainer, filter);
    } else {
      checkedFilters[filter.name].push(filterValue);
    }
    if (checkObject) {
        checkObject.checked = existIndex === -1;
    }
    this.searchExecutorService.resetSearchPosition(searchContainer);
    this.setFiltersChecked(searchContainer);
    if (!noSearch) {
      searchContainer.selections.allSelected = false;
      await this.searchExecutorService.runSearch(searchContainer);
      await this.setCheckFilterMenusAfterSearch(searchContainer);
      this.setTotalSelectedFilters(searchContainer);
    } else {
      this.setTotalSelectedFilters(searchContainer);
    }
  }

  private checkFilterExists(filterValues: any[], filterValue: any) {
    return filterValues.indexOf(filterValue);
  }

  private checkMenuFiltersFromDefaultFilters(searchContainer: SearchContainer, defFilters: any) {

    this.loopMenusAndFilters(searchContainer, defFilters,
      (menu: SearchViewMenu) => {
        this.checkMenuFilter(menu, searchContainer, true).then();
      });
  }

  checkMenusFromCheckedFilters(searchContainer: SearchContainer) {
    this.loopMenusAndFilters(searchContainer, this.getCheckedFilters(searchContainer),
      (menu: SearchViewMenu) => {
        menu.checked = true;
      });
  }

  private loopMenusAndFilters(searchContainer: SearchContainer, filters: any, fn: any) {
    const categoryMenus = this.categoryMenuService.getCategoryMenus(searchContainer.currentPathView.search_view.menus);
    if (filters && categoryMenus) {
        for (const menu of categoryMenus) {
        this.loopFindMenuFilters(menu, filters, fn);
      }
    }
  }

  private loopFindMenuFilters(menu: SearchViewMenu, filters: object, fn: any) {
    for (const [fName, values] of Object.entries(filters)) {
      if (menu.facet === fName) {
        const valIndex = values.indexOf(menu.facet_values[0]);
        if (valIndex !== -1) {
          fn(menu);
        }
      }
    }
  }

  private deleteCheckFilterValue(filterValues: any[], filterValue: any) {
    const index = this.checkFilterExists(filterValues, filterValue);
    if (index !== -1) {
      filterValues.splice(index, 1);
    }
  }

  private getFacetRangeFromTitle(facetRanges: Array<Facet>, title: string) {
    let res = null;
    for (const facetRange of facetRanges) {
      if (facetRange.title === title) {
        res = facetRange;
        break;
      }
    }
    return res;
  }

  private async unCheckOldRangeFilters(rangeGroup: RangeGroup, rangeName: string, searchContainer: SearchContainer) {
    let res = false;
    for (const oldRange of rangeGroup.facet_ranges) {
      const oldRangeName = this.getRangeFilterName(oldRange);
      if (oldRange.checked) {
        await this.checkFilter(new CheckFilter(oldRangeName, this.getRangeFilterValue(oldRange)),
          oldRange, searchContainer, false);
        if (oldRangeName === rangeName) {
          res = true;
        }
      }
    }
    return res;
  }

  private setRangeFromFilter(range: Facet, filterValues: any[]) {
    if (range.start) {
      if (range.$$origStart === undefined) {
        range.$$origStart = range.start;
        range.$$origEnd = range.end;
      }
      for (const val of filterValues) {
        const split = val.substring(1, val.length - 1).split(' TO ');
        range.start = split[0];
        range.end = split[1];
      }
    }
  }

  private getNamedFilterGroup(searchContainer: SearchContainer, name: string): CheckFilterGroup {
    let res: CheckFilterGroup;
    for (const filterGroup of searchContainer.filtersFacets.filterGroups) {
      if (!filterGroup.filterNames) {
        this.logger.warn('Filter group ' + filterGroup.title + ' has no filter names');
        continue;
      }
      if (filterGroup.filterNames.includes(name)) {
        res = filterGroup;
        break;
      }
    }
    if (!res) {
      console.warn('Could not find filter group ' + name);
    }
    return res;
  }

  private getFacetItemFromFilter(facet: Facet, filter: CheckFilter): FacetItem {
    let res = new FacetItem();
    res.count = 0;
    for (const item of facet.items) {
      if (!item.id) {
        console.warn('No item id!');
      }
      if (item.id === filter.value) {
        res = item;
      }
    }
    return res;
  }

  private findDeleteFilterGroupFilter(filters: Array<CheckFilter>, valueField: string, filter: CheckFilter) {
    const deleteIndex = filters.map(f => {
      return f[valueField];
    }).indexOf(filter.value);
    if (deleteIndex !== -1) {
      filters.splice(deleteIndex, 1);
    }
  }

  resetFilter(searchContainer: SearchContainer) {
    if (!searchContainer.focus.curFocusId) {
      this.setCheckedFilters(
        searchContainer, {});
    }
    this.setFiltersChecked(searchContainer);
    this.resetMenuChecks(searchContainer);
    this.setCheckFilterGroups(searchContainer, true)
    searchContainer.filtersFacets.facetCount = {};
  }

  private resetMenuChecks(searchContainer: SearchContainer) {
    this.loopPathViewMenus((menu: SearchViewMenu) => {
      delete menu.checked;
    }, searchContainer);
  }

  private loopPathViewMenus(fn: any, searchContainer: SearchContainer) {
    const menus = this.categoryMenuService.getCategoryMenus(searchContainer.currentPathView.search_view.menus);
    if (menus) {
      for (const mainMenu of menus) {
        fn(mainMenu);
        if (mainMenu.menus) {
          for (const subMenu of mainMenu.menus) {
            fn(subMenu);
          }
        }
      }
    }
  }

  private getMaxFilters(filterGroup: CheckFilterGroup, filters: Array<CheckFilter>): Array<CheckFilter> {
    const res: Array<CheckFilter> = [];
    for (let index = 0; index < filters.length; index++) {
      if (this.checkMaxFilterLen(filterGroup, index)) {
        res.push(filters[index]);
      } else {
        break;
      }
    }
    return res;
  }

  private checkMaxFilterLen(filterGroup: CheckFilterGroup, index: number) {
    let res = true;
    filterGroup.filterFilter.hasMore = false;
    if (index >= AConst.MAX_FILTER_LENGTH && index >= filterGroup.filterFilter.showMoreCount) {
      filterGroup.filterFilter.hasMore = true;
      res = false;
    }
    return res;
  }

  private getQueriedFilters(checkFilterGroup: CheckFilterGroup, filters: CheckFilter[]): CheckFilter[] {
    const res = [];
    checkFilterGroup.filterFilter.filterCount = 0;
    for (let index = 0; index < filters.length; index++) {
      const filter = filters[index];
      const name = this.getFilterTitle(filter);
      let found = true;
      if (!filter.checked && checkFilterGroup.filterFilter.query) {
        const q = checkFilterGroup.filterFilter.query.toLocaleLowerCase();
        found = name.toLocaleLowerCase().indexOf(q) !== -1;
      }
      if (found) {
        res.push(filter);
        checkFilterGroup.filterFilter.filterCount++;
      }
      if (index === filters.length - 1) {
        break;
      }
    }
    return res;
  }

  private getFilterTitle(filter: CheckFilter): string {
    let res: string;
    if (filter.noTransTitle) {
      res = filter.noTransTitle;
    } else if (filter.title) {
      res = this.translate.instant(filter.title);
    } else {
      res = 'NO TITLE';
    }
    return res;
  }

}
