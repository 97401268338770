<div class="filter-group">
  @for(filter of filterGroup.visibleFilters; track filter) {
    <div class="filter-group__item">
      <mat-checkbox
        class="filter-group__item__checkbox"
        (click)="checkFilter(filter)"
        [(ngModel)]="filter.checked"
      >
      @if (filter.title !== '' && filter.title !== null && filter.title !== undefined) {
        <span class="filter-group__item__label--body-medium-grow">
          {{filter.title | translate}}
        </span>
      }
      @else {
        <span class="filter-group__item__label--body-medium-grow">
          {{filter.noTransTitle}}
        </span>
      }

      @if (filter.count) {
        <span class="filter-group__item__label--label-small filter-group__item__label--subtle">
          {{filter.count}}
        </span>
      }
      </mat-checkbox>
    </div>
  }
</div>
