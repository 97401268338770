<app-widget-container>
  <h2>{{ 'TRANS__FEATURE_FLAGS_WIDGET__TITLE' | translate }}</h2>
  <p class="description">
    {{ 'TRANS__FEATURE_FLAGS_WIDGET__DESCRIPTION' | translate }}
  </p>
  <form [formGroup]="form">
    <div formGroupName="experimental">
      <div class="flag">
        <div>
          <mat-checkbox formControlName="useNewObjectPage">
            {{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_OBJECT_PAGE_TITLE' | translate }}
          </mat-checkbox>
        </div>
        <span class="description">
          {{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_OBJECT_PAGE_DESCRIPTION' | translate }}
      </span>
      </div>

      <div class="flag">
        <div>
          <mat-checkbox formControlName="useNewFilterMenu">
            {{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_FILTER_MENU_TITLE' | translate }}
          </mat-checkbox>
        </div>

        <span class="description">
          {{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_FILTER_MENU_DESCRIPTION' | translate }}
        </span>
      </div>
    </div>

    <div class="button-container">
      <button mat-stroked-button type="submit" (click)="saveFlags()">
        {{ 'TRANS__FEATURE_FLAGS_WIDGET__SAVE_BUTTON_LABEL' | translate }}
      </button>
      <button mat-button type="button" (click)="resetFlags()">
        {{ 'TRANS__FEATURE_FLAGS_WIDGET__RESET_TO_DEFAULT_BUTTON_LABEL' | translate }}
      </button>
    </div>
  </form>
</app-widget-container>
