@if (loading) {
  <div class="flex justify-center">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
} @else if (sectionsContainer) {

  <mat-card class="overview-card">
    <app-object-overview-fields-v2
      [objectView]="sectionsContainer.objectView"
      [isEditing]="isEditing"
    >
      <span slot="title" class="overview-title">{{ 'TRANS__SECTION__OVERVIEW__TITLE' | translate }}</span>
    </app-object-overview-fields-v2>
  </mat-card>

  <app-object-view-and-edit
    class="legacy"
    [isEditing]="isEditing"
    [sectionsContainer]="sectionsContainer"
    [templateGroupId]="contentInfo.templateGroupId"
  ></app-object-view-and-edit>
  @if (isEditing && operationStep) {
    <app-operation-toolbar
      [operationContainer]="operationContainer"
      [operationStep]="operationStep"
    >
    </app-operation-toolbar>
  }
}
